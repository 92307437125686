import React from "react";
import styles from "./closed-courses.module.scss";
import Spacing from "@components/ui/spacing";
import Title from "@components/ui/title";
import ActionLink from "@components/ui/action-link";
import CourseCard from "@components/ui/course-card";
import { Link } from "gatsby";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import PropTypes from "prop-types";
import { useCoursesMetadata } from "@queries/use-courses-metadata";
import { isCourseClosedForSignUp, getPathToCoursePage } from "@utilities/course-data-helpers";

const RenderCourseCards = (finishedCourses, limit) => {
  if (finishedCourses && finishedCourses.length === 0) {
    return null;
  }

  return finishedCourses.slice(0, limit).map((node) => {
    const {
      course: {
        name
      },
      image,
      scholarity
    } = node;
    const path = getPathToCoursePage(node);

    return (
      <div key={node.course_iter_id}>
        <Link to={path}>
          <CourseCard
            title={name}
            subtitle={scholarity}
            imageUrl={image !== null ? image.localFile.childImageSharp.original.src : null}
          />
        </Link>
      </div>
    );
  });
};

const ClosedCourses = (props) => {
  const { allCourses} = useCoursesMetadata();
  const courses = allCourses.filter(isCourseClosedForSignUp);
  const uniqueCourses = courses.filter((item, index, array) =>
    index === array.findIndex(foundItem => foundItem.course.id === item.course.id)
  );

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(255px, 1fr))",
    gridColumnGap: "30px",
    gridRowGap: "30px",
    justifyItems: "center",
  };

  const sliderParams = {
    width: 255,
    spaceBetween: 30,
    updateOnWindowResize: true,
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <section>
          <Title value="Cursos que fizeram história" />
        </section>
        <section className={styles.mobileHidden}>
          <div>
            <ActionLink path="/cursos" label="ver todos" width="255px" />
          </div>
        </section>
      </div>

      <Spacing>
        <div className={styles.desktopCourseList}>
          <div style={{ ...gridStyles }}>
            {RenderCourseCards(uniqueCourses, props.limit)}
          </div>
        </div>
      </Spacing>

      <div className={styles.mobileCourseList}>
        <Swiper {...sliderParams}>
          {RenderCourseCards(uniqueCourses, props.limit)}
        </Swiper>
      </div>

      <section className={`${styles.action} ${styles.desktopHidden}`}>
        <ActionLink path="/cursos" label="ver todos" width="auto" />
      </section>
    </div>
  );
};

export default ClosedCourses;

ClosedCourses.defaultProps = {
  limit: 8,
};

ClosedCourses.propTypes = {
  limit: PropTypes.number,
};
